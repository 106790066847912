<template>
  <div class="page">
    <el-scrollbar class="Yscrollbar" style="height: calc(100% - 8vh)">
      <LabelContent title="商业发布编辑" ref="LabelContent"></LabelContent>
      <el-form :inline="true" class="arrange-form" size="small" style="text-align: left">
        <el-col :span="6">
          <el-form-item style="margin-left: 1vw" label="标题：">
            <el-input v-model="fromquery.title" placeholder="请输入"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item style="margin-left: 1vw" label="文章有效期">
            <el-date-picker
              v-model="time"
              type="daterange"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd HH:hh:mm"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col>
          <el-quill-editor @UploadFiles="UploadFiles" :content="fromquery.content">
          </el-quill-editor>
        </el-col>
      </el-form>
    </el-scrollbar>
    <div class="bottomBtnBox">
      <el-button class="long" @click="submit" type="primary" size="small">提交</el-button>
      <el-button class="long" @click="cancel" type="info" size="small">取消</el-button>
    </div>
  </div>
</template>
<script>
import vue_quill_editor from "../../components/public/vue-quill-editor"
export default {
  name: "TestPage_detail",
  components: {
    "el-quill-editor": vue_quill_editor
  },
  data () {
    return {
      time: [],  //有效时间
      fromquery: {
        title: "",
        content: '', //富文本框内容
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "一周",
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 7)
              picker.$emit("pick", [start, end])
            },
          },
          {
            text: "一个月",
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 30)
              picker.$emit("pick", [start, end])
            },
          },
          {
            text: "三个月",
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 90)
              picker.$emit("pick", [start, end])
            },
          },
        ],
      },
    }
  },
  mounted () {
    this.fromquery.artId = this.$route.params.artId
    this.init()
  },
  methods: {
    UploadFiles (files) {
      this.fromquery.files = files
    },
    async init () {
      let that = this
      let result = await that.$http.request({
        url: "/hw/admin/articles/bw/detail/" + this.fromquery.artId,
        method: "GET",
        params: that.query,
      })
      if (result.status == 100) {
        let { data } = result
        that.fromquery = data
        //that.fromquery.endTime
        that.time.push(that.fromquery.startTime)
        that.time.push(that.fromquery.endTime)
        // console.log(that.fromquery)
      }
    },
    //修改
    async submit () {
      let value = document.querySelector('.ql-container .ql-editor').innerHTML
      this.$emit('input', value)
      let fromquery = {
        artId: this.fromquery.artId,
        title: this.fromquery.title,
        startTime: this.formatDate(this.time[0]),
        endTime: this.formatDate(this.time[1]),
        content: value,
        files: this.fromquery.files,
        token: this.lsGet("userInfo").token,
        files: [646],
      }
      let data = await this.$http
        .request({
          url: "/hw/admin/articles/business_wire/save",
          method: "POST",
          data: fromquery,
          showLoading: true,
          headers: {
            "Content-Type": "application/json;charset=UTF-8"
          }
        })
      if (data.status == 100) {
        this.$message({
          message: '修改成功',
          type: 'success'
        })
        this.jump("/releaseentry")
      }
    },
    cancel () {
      this.jump("/releaseentry")
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
